import '../assets/CustomTable.css';
import '../assets/Contacts.css';
import ContactsLoader from './ContactsLoader';

const Contacts = ({ contacts, handleContactCheckbox, resultsForHubspot, contactsLoading, contactsCount = 0 }) => {
    const renderEmptyRow = (key) => (
        <tr key={`contacts_empty_row_${key}`} className="empty-row">
            {[...Array(8)].map((x, i) => <td key={`contacts_empty_cell_${key}_${i}`}/>)}
        </tr>
    );

    const renderContacts = () => {
        if (contactsLoading && contactsCount > 0) {
            return <ContactsLoader numberOfRows={contactsCount}/>;
        }

        return (
            <table className="custom-table contacts-table">
                <thead>
                    <tr>
                        <th className="header1">Create</th>
                        <th className="header2">CRM</th>
                        <th className="header3">First name</th>
                        <th className="header4">Last name</th>
                        <th className="header5">Job title</th>
                        <th className="header6">Worksite name</th>
                        <th className="header7">City</th>
                        <th className="header8">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {!contacts ?
                        renderEmptyRow(1) :
                        contacts.map(contact => (
                            <tr key={contact.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={!!resultsForHubspot[contact.worksite?.id]?.contacts[contact.id]}
                                        value={contact.id}
                                        onChange={(event) => handleContactCheckbox(contact, event.target.checked)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={!!contact.existInDb}
                                        readOnly
                                    />
                                </td>
                                <td>{contact.firstName}</td>
                                <td>{contact.lastName}</td>
                                <td>{contact.mainPosition?.description}</td>
                                <td>{contact.worksite?.name}</td>
                                <td>{contact.worksite?.postalAddress?.city}</td>
                                <td>{contact.status?.name}</td>
                            </tr>
                        ))}
                </tbody>
            </table>);
    };

    return (
        <div>
            <h4>{`Contacts (${contacts?.length || 0}):`}</h4>
            {renderContacts()}
        </div>
    );
};

export default Contacts;
