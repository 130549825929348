import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    useLocation
} from 'react-router-dom';
import Axios from 'axios';

import Home from './Home';
import Unauthorized from './components/Unauthorized';
import { API_URL } from './config';

const UnauthorizedRoute = () => <Route path="/*" exact component={Unauthorized}/>;

const BisnodeSearchRoute = (props) => {
    const [validAccount, setValidAccount] = useState();
    const location = useLocation();
    const apiKey = new URLSearchParams(location.search).get('api_key');

    useEffect(() => {
        (async () => {
            const account = await Axios.post(`${API_URL}/api/bisnode/validateRequest`, { apiKey });
            setValidAccount(account.data);
        })();
    }, [apiKey]);
    return (
        validAccount ?
            <Route
                path="/"
                exact
                render={() => (
                    <Home {...props} account={validAccount}/>
                )}
            /> :
            <UnauthorizedRoute/>
    );
};

const Routes = () => (
    <Router>
        <Switch>
            <Route
                path="/"
                exact
                component={BisnodeSearchRoute}
            />
            <UnauthorizedRoute/>
        </Switch>
    </Router>
);

export default Routes;