const ContactsLoader = ({ numberOfRows }) => (
    <table className="custom-table contacts-table">
        <thead>
            <tr>
                <th className="header1">Create</th>
                <th className="header2">CRM</th>
                <th className="header3">First name</th>
                <th className="header4">Last name</th>
                <th className="header5">Job title</th>
                <th className="header6">Worksite name</th>
                <th className="header7">City</th>
                <th className="header8">Status</th>
            </tr>
        </thead>
        <tbody>
            {[...Array(numberOfRows)].map((x, i) => (
                <tr key={i}>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item1"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item2"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item3"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item4"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item5"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item6"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item7"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item8"/>
                    </td>
                </tr>))}
        </tbody>
    </table>
);

export default ContactsLoader;
