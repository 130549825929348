const WorksitesLoader = () => (
    <table className="custom-table worksites-table">
        <thead>
            <tr>
                <th className="header1">Create</th>
                <th className="header2">CRM</th>
                <th className="header3">Name</th>
                <th className="header4">City</th>
                <th className="header5">Visiting Address</th>
                <th className="header6">Visiting City</th>
                <th className="header7">Status</th>
                <th className="header8">HQ</th>
                <th className="header9">Contacts</th>
            </tr>
        </thead>
        <tbody>
            {[...Array(10)].map((x, i) => (
                <tr key={`worksites_placeholder_${i}`}>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item1"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item2"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item3"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item4"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item5"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item6"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item7"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item8"/>
                    </td>
                    <td>
                        <div className="placeholder-content_item placeholder-content_item9"/>
                    </td>
                </tr>))}
        </tbody>
    </table>);

export default WorksitesLoader;
