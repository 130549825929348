import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import SnackBar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ClipLoader from 'react-spinners/ClipLoader';

import { API_URL } from './config';
import Worksites from './components/Worksites';

import './assets/Home.css';
import './assets/Modal.css';

Modal.setAppElement('#root');
const defaultModalStyles = {
    content: {
        top: '52%',
        left: '50%',
        right: '50%',
        bottom: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '180px',
        display: 'flex'
    }
};
const initialLoaderModalStyle = {
    content: {
        top: '52%',
        left: '50%',
        right: '50%',
        bottom: '50%',
        marginRight: 'auto',
        marginLeft: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        height: '300px',
        display: 'flex',
        backgroundColor: 'transparent',
        border: 'none'
    }
};

const Home = props => {
    const [countries, setCountries] = useState();
    const [searchParams, setSearchParams] = useState({ filters: {}, offset: 0 });
    const [searchResults, setSearchResults] = useState({});
    const [worksitesData, setWorksitesData] = useState();
    const [worksitesWithDetails, setWorksitesWithDetails] = useState({});
    const [resultsForHubspot, setResultsForHubspot] = useState({});
    const [worksitesLoading, setWorksitesLoading] = useState(false);
    const [showSearchWarningModal, setShowSearchWarningModal] = useState(false);
    const [showStoreWarningModal, setShowStoreWarningModal] = useState(false);
    const [selectedWorksitePage, setSelectedWorksitePage] = useState(1);
    const [showLoadingModal, setShowLoadingModal] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [fatalError, setFatalError] = useState(false);
    const { apiKey } = props.account;
    const { portalId } = props.account;

    useEffect(async () => {
        try {
            const results = await axios.post(`${API_URL}/api/company/getCountries`, { apiKey });
            setCountries(results.data);
        } catch (error) {
            setErrorMessage('Error loading countries!');
            setShowError(true);
        }
        setShowLoadingModal(false);
    }, []);

    const searchWorksites = async (newSearch = true, offset = 0) => {
        if (!searchParams.filters && Object.keys(searchParams.filters).length === 0) {
            return;
        }

        setWorksitesLoading(true);

        if (newSearch) {
            setSearchResults({});
            setWorksitesData(null);
            setResultsForHubspot({});
            setSelectedWorksitePage(1);
        }

        try {
            const worksites = await axios.post(`${API_URL}/api/company/searchWorksites`,
                { searchParams: { ...searchParams, offset }, apiKey });
            setWorksitesData(worksites.data);
            setSearchResults(prevState => ({ ...prevState, [worksites.data.offset]: { ...worksites.data.worksites } }));
        } catch (error) {
            setErrorMessage('Error searching for worksites!');
            setShowError(true);
        }
        setWorksitesLoading(false);
    };

    const handlePageChange = (event, value) => {
        const offset = (value * 10) - 10;
        setSelectedWorksitePage(value);

        if (searchResults[offset]) {
            setWorksitesData({ ...worksitesData, worksites: searchResults[offset] });
            return;
        }

        searchWorksites(false, offset);
    };

    const processResultsForHubspot = async () => {
        try {
            await axios.post(`${API_URL}/api/company/processResultsForHubspot`, { resultsForHubspot, apiKey, portalId });
            setWorksitesWithDetails({});
            setSelectedWorksitePage(1);
            searchWorksites();
        } catch (error) {
            setErrorMessage('Error saving worksites and contacts to Hubspot!');
            setShowError(true);
        }
    };

    const store = async () => {
        setShowStoreWarningModal(false);
        setShowLoadingModal(true);
        await processResultsForHubspot();
        setShowLoadingModal(false);
    };

    const confirmStore = () => {
        setShowStoreWarningModal(true);
    };

    const search = () => {
        setFatalError(false);
        if (Object.keys(resultsForHubspot).length) {
            setShowSearchWarningModal(true);
        } else {
            searchWorksites();
        }
    };

    const handleClose = () => {
        setShowError(false);
    };

    return (
        <div>
            <SnackBar
                open={showError}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleClose}
            >
                <Alert severity="error">
                    {errorMessage}
                </Alert>
            </SnackBar>
            <Modal isOpen={showLoadingModal} style={initialLoaderModalStyle}>
                <div className="modal-content">
                    <ClipLoader
                        size="200px"
                        color="#ff7a59"
                    />
                </div>
            </Modal>
            <h3 className="navbar-container">Search and sync Bisnode data</h3>
            <div className="content">
                <Modal isOpen={showSearchWarningModal} style={defaultModalStyles}>
                    <div className="modal-content">
                        <p className="modal-text">
                            You have data selected for storing on HubSpot.
                            New search will remove previosly selected worksites and contacts.
                            Are you sure you want to continue?
                        </p>
                        <div className="button-wrapper">
                            <button
                                className="modal-cancel-button"
                                type="button"
                                onClick={() => setShowSearchWarningModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="modal-continue-button"
                                onClick={() => {
                                    setShowSearchWarningModal(false);
                                    searchWorksites();
                                }}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </Modal>
                <Modal isOpen={showStoreWarningModal} style={defaultModalStyles}>
                    <div className="modal-content">
                        <p className="modal-text">
                            You are about to store selected worksites/contacts on HubSpot.
                        </p>
                        <div className="button-wrapper">
                            <button
                                className="modal-cancel-button"
                                type="button"
                                onClick={() => setShowStoreWarningModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="modal-continue-button"
                                onClick={store}
                            >
                                Store
                            </button>
                        </div>
                    </div>
                </Modal>
                <div className="filter-component">
                    <div className="filter-object margin-right-20">
                        <div className="filter-wrapper">
                            <label htmlFor="company-name" className="filter-label">Company name:</label>
                            <input
                                className="filter"
                                id="company-name"
                                placeholder="Company worksite name"
                                onChange={(event) => setSearchParams({
                                    ...searchParams,
                                    filters: { ...searchParams.filters, generalName: event.target.value || null }
                                })}
                            />
                        </div>
                        <div className="filter-wrapper">
                            <label htmlFor="org-number" className="filter-label">Company number:</label>
                            <input
                                id="org-number"
                                className="filter"
                                placeholder="Company number"
                                onChange={(event) => setSearchParams({
                                    ...searchParams,
                                    filters: { ...searchParams.filters, companyNumber: event.target.value || null }
                                })}
                            />
                        </div>
                        <div className="filter-wrapper">
                            <label htmlFor="country" className="filter-label">Country:</label>
                            <select
                                id="country"
                                placeholder="Country"
                                onChange={(event) => setSearchParams({
                                    ...searchParams,
                                    filters: { ...searchParams.filters, country: event.target.value || null }
                                })}
                            >
                                {countries?.map((country, index) => <option key={index} value={country.code}>{country.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="filter-object margin-right-20">
                        <div className="filter-wrapper">
                            <label htmlFor="worksiteGeneralAddress" className="filter-label">Address:</label>
                            <input
                                id="worksiteGeneralAddress"
                                className="filter"
                                placeholder="Address"
                                onChange={(event) => setSearchParams({
                                    ...searchParams,
                                    filters: { ...searchParams.filters, worksiteGeneralAddress: event.target.value || null }
                                })}
                            />
                        </div>
                        <div className="filter-wrapper">
                            <label htmlFor="worksiteGeneralCity" className="filter-label">City:</label>
                            <input
                                id="worksiteGeneralCity"
                                className="filter"
                                placeholder="City"
                                onChange={(event) => setSearchParams({
                                    ...searchParams,
                                    filters: { ...searchParams.filters, worksiteGeneralCity: event.target.value || null }
                                })}
                            />
                        </div>
                        <div className="filter-wrapper">
                            <label htmlFor="worksiteGeneralZipcode" className="filter-label">Zip code:</label>
                            <input
                                id="worksiteGeneralZipcode"
                                className="filter"
                                placeholder="Zip Code"
                                onChange={(event) => setSearchParams({
                                    ...searchParams,
                                    filters: { ...searchParams.filters, worksiteGeneralZipcode: event.target.value || null }
                                })}
                            />
                        </div>
                    </div>
                    <div className="filter-object">
                        <div className="filter-wrapper">
                            <label htmlFor="contact-firstname" className="filter-label">First name:</label>
                            <input
                                id="contact-firstname"
                                className="filter"
                                placeholder="Contact first name"
                                onChange={(event) => setSearchParams({
                                    ...searchParams,
                                    filters: { ...searchParams.filters, contactFirstName: event.target.value || null }
                                })}
                            />
                        </div>
                        <div className="filter-wrapper">
                            <label htmlFor="contact-lastname" className="filter-label">Last name:</label>
                            <input
                                id="contact-lastname"
                                className="filter"
                                placeholder="Contact last name"
                                onChange={(event) => setSearchParams({
                                    ...searchParams,
                                    filters: { ...searchParams.filters, contactLastName: event.target.value || null }
                                })}
                            />
                        </div>
                        <div className="filter-wrapper">
                            <label htmlFor="contact-email" className="filter-label">Email:</label>
                            <input
                                id="contact-email"
                                className="filter"
                                placeholder="Email"
                                onChange={(event) => setSearchParams({
                                    ...searchParams,
                                    filters: { ...searchParams.filters, email: event.target.value || null }
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="filter-object-split">
                    <div className="filter-wrapper">
                        <button
                            type="button"
                            className="search-button"
                            id="search-button"
                            onClick={search}
                            disabled={!Object.values(searchParams.filters).some(filter => filter !== null && filter !== '')}
                        >
                            Search worksites
                        </button>
                    </div>
                    <div className="filter-wrapper">
                        <button
                            type="button"
                            className="store-button"
                            disabled={!Object.keys(resultsForHubspot).length || fatalError}
                            onClick={confirmStore}
                        >
                            Store on HubSpot
                        </button>
                    </div>
                </div>
                <Worksites
                    worksitesData={worksitesData}
                    resultsForHubspot={resultsForHubspot}
                    setResultsForHubspot={setResultsForHubspot}
                    apiKey={props.account?.apiKey}
                    portalId={props.account?.portalId}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    handlePageChange={handlePageChange}
                    worksitesLoading={worksitesLoading}
                    selectedWorksitePage={selectedWorksitePage}
                    setShowError={setShowError}
                    setErrorMessage={setErrorMessage}
                    setFatalError={setFatalError}
                    worksitesWithDetails={worksitesWithDetails}
                    setWorksitesWithDetails={setWorksitesWithDetails}
                />
            </div>
        </div>
    );
};

export default Home;
